import React, {lazy, Suspense} from "react";
import {Switch, Route, withRouter} from "react-router-dom";
import "intersection-observer";
import {Consumer} from "./Context";
// import Cursor from "./components/Cursor/Cursor";
import Helmet from "react-helmet/es/Helmet";
import 'video-react/dist/video-react.css'
/*import Menu from "./components/Menu/Menu";*/
import MiHeader from './components/NewMenu/MiHeader'
import MIMobileHeader from "./components/NewMenu/MiMobileHeader";
import Menu from "./components/Menu/Menu";
import * as serviceWorker from './serviceWorker';
/*import MiMobileDropdown from "./components/NewMenu/MiMobileDropdown";*/

const GridLines = lazy(() => import("./components/GridLines/GridLines"));
/*const Sidebar = lazy(() => import("./components/Sidebar/Sidebar"));
const Header = lazy(() => import("./components/Header/Header"));*/

/*import CaseStudy from "./components/CaseStudy/CaseStudy";*/
const Footer = lazy(() => import("./components/Footer/Footer"));
const NewFooter = lazy(() => import("./components/NewFooter/NewFooter"));

const CookiePopup = lazy(() => import("./components/CookiePopup/CookiePopup"));
// const CaseStudy = lazy(() =>
//   import("./components/CaseStudy/CaseStudy")
// );
const Home = lazy(() => import("./pages/Home/Home"));
const Services = lazy(() => import("./pages/Services/Services"));
const Technologies = lazy(() => import("./pages/Technologies/Technologies"));
const MobileAppDevelopment = lazy(() =>
    import("./pages/MobileAppDevelopment/MobileAppDevelopment")
);
const IphoneAppDevelopment = lazy(() =>
    import("./pages/IphoneAppDevelopment/IphoneAppDevelopment")
);
const AndroidAppDevelopment = lazy(() =>
    import("./pages/AndroidAppDevelopment/AndroidAppDevelopment")
);
/*const IpadAppDevelopment = lazy(() =>
    import("./pages/IpadAppDevelopment/IpadAppDevelopment")
);*/
const SwiftAppDevelopment = lazy(() =>
    import("./pages/SwiftAppDevelopment/SwiftAppDevelopment")
);
const ReactNativeDevelopment = lazy(() =>
    import("./pages/ReactNativeDevelopment/ReactNativeDevelopment")
);
const FlutterAppDevelopment = lazy(() =>
    import("./pages/FlutterAppDevelopment/FlutterAppDevelopment")
);
/*const IonicDevelopment = lazy(() =>
    import("./pages/IonicDevelopment/IonicDevelopment")
);*/
const JavaScriptDevelopment = lazy(() =>
    import("./pages/JavaScriptDevelopment/JavaScriptDevelopment")
);
const AngularDevelopment = lazy(() =>
    import("./pages/AngularDevelopment/AngularDevelopment")
);
const ReactJsDevelopment = lazy(() =>
    import("./pages/ReactJsDevelopment/ReactJsDevelopment")
);
const VueJsDevelopment = lazy(() =>
    import("./pages/VueJsDevelopment/VueJsDevelopment")
);
const MeteorJsDevelopment = lazy(() =>
    import("./pages/MeteorJsDevelopment/MeteorJsDevelopment")
);
const BackboneJsDevelopment = lazy(() =>
    import("./pages/BackboneJsDevelopment/BackboneJsDevelopment")
);
const WebDevelopment = lazy(() =>
    import("./pages/WebDevelopment/WebDevelopment")
);
const PhpDevelopment = lazy(() =>
    import("./pages/PhpDevelopment/PhpDevelopment")
);
const LaravelDevelopment = lazy(() =>
    import("./pages/LaravelDevelopment/LaravelDevelopment")
);
const NodeJsDevelopment = lazy(() =>
    import("./pages/NodeJsDevelopment/NodeJsDevelopment")
);
/*const CakePhpDevelopment = lazy(() =>
    import("./pages/CakePhpDevelopment/CakePhpDevelopment")
);*/
const GoLangDevelopment = lazy(() =>
    import("./pages/GoLangDevelopment/GoLangDevelopment")
);
/*const RubyOnRailsDevelopment = lazy(() =>
    import("./pages/RubyOnRailsDevelopment/RubyOnRailsDevelopment")
);*/
const PythonWebDevelopment = lazy(() =>
    import("./pages/PythonWebDevelopment/PythonWebDevelopment")
);
/*const BlockChainDevelopment = lazy(() =>
    import("./pages/BlockChainDevelopment/BlockChainDevelopment")
);
const JoomlaDevelopment = lazy(() =>
    import("./pages/JoomlaDevelopment/JoomlaDevelopment")
);
const WordpressDevelopment = lazy(() =>
    import("./pages/WordpressDevelopment/WordpressDevelopment")
);
const MagentoDevelopment = lazy(() =>
    import("./pages/MagentoDevelopment/MagentoDevelopment")
);
const DrupalDevelopment = lazy(() =>
    import("./pages/DrupalDevelopment/DrupalDevelopment")
);*/
const IotAppDevelopment = lazy(() =>
    import("./pages/IotAppDevelopment/IotAppDevelopment")
);
const GameDevelopment = lazy(() =>
    import("./pages/GameDevelopment/GameDevelopment")
);
const WearableAppDevelopment = lazy(() =>
    import("./pages/WearableAppDevelopment/WearableAppDevelopment")
);
const IbeaconAppDevelopment = lazy(() =>
    import("./pages/IbeaconAppDevelopment/IbeaconAppDevelopment")
);
const Design = lazy(() => import("./pages/Design/Design"));
const EnterpriseAppDevelopment = lazy(() =>
    import("./pages/Solutions/EnterpriseAppDevelopment/EnterpriseAppDevelopment")
);
const ProductEngineeringServices = lazy(() =>
    import(
        "./pages/Solutions/ProductEngineeringServices/ProductEngineeringServices"
        )
);
const StartupsAppDev = lazy(() =>
    import("./pages/Solutions/StartupsAppDev/StartupsAppDev")
);
const DevOpsSplutions = lazy(() =>
    import("./pages/Solutions/DevOpsSplutions/DevOpsSplutions")
);
const DigitalTransformation = lazy(() =>
    import("./pages/Solutions/DigitalTransformation/DigitalTransformation")
);
const EcommerceSolutions = lazy(() =>
    import("./pages/Solutions/EcommerceSolutions/EcommerceSolutions")
);
const FintechSolutions = lazy(() =>
    import("./pages/Solutions/FintechSolutions/FintechSolutions")
);
const HealthCareSoultions = lazy(() =>
    import("./pages/Solutions/HealthCareSoultions/HealthCareSoultions")
);
const FitnessSoultions = lazy(() =>
    import("./pages/Solutions/FitnessSoultions/FitnessSoultions")
);
const FoodDeliverySolutions = lazy(() =>
    import("./pages/Solutions/FoodDeliverySolutions/FoodDeliverySolutions")
);
const CloudComputingSolutions = lazy(() =>
    import("./pages/Solutions/CloudComputingSolutions/CloudComputingSolutions")
);
const OnDemandAppDev = lazy(() =>
    import("./pages/Solutions/OnDemandAppDev/OnDemandAppDev")
);
const RealEstateAppDevelopment = lazy(() =>
    import("./pages/Solutions/RealEstateAppDevelopment/RealEstateAppDevelopment")
);
const TravelAppDevelopment = lazy(() =>
    import("./pages/Solutions/TravelAppDevelopment/TravelAppDevelopment")
);
const SportsAppDevelopment = lazy(() =>
    import("./pages/Solutions/SportsAppDevelopment/SportsAppDevelopment")
);
const SocialMediaAppDevelopment = lazy(() =>
    import(
        "./pages/Solutions/SocialMediaAppDevelopment/SocialMediaAppDevelopment"
        )
);
const BusinessAppDevelopment = lazy(() =>
    import("./pages/Solutions/BusinessAppDevelopment/BusinessAppDevelopment")
);
const EducationAppDevelopment = lazy(() =>
    import("./pages/Solutions/EducationAppDevelopment/EducationAppDevelopment")
);
const EntertainmentAppDevelopment = lazy(() =>
    import(
        "./pages/Solutions/EntertainmentAppDevelopment/EntertainmentAppDevelopment"
        )
);
const HireDevelopers = lazy(() =>
    import("./pages/Hire/HireDevelopers/HireDevelopers")
);
const HireGameDevelopers = lazy(() =>
    import("./pages/Hire/HireGameDevelopers/HireGameDevelopers")
);
const HireIphoneDevelopers = lazy(() =>
    import("./pages/Hire/HireIphoneDevelopers/HireIphoneDevelopers")
);
const HireAndroidDevelopers = lazy(() =>
    import("./pages/Hire/HireAndroidDevelopers/HireAndroidDevelopers")
);
/*const HireIpadDevelopers = lazy(() =>
    import("./pages/Hire/HireIpadDevelopers/HireIpadDevelopers")
);*/
/*const HireIonicDevelopers = lazy(() =>
    import("./pages/Hire/HireIonicDevelopers/HireIonicDevelopers")
);*/
const HireReactNativeDevelopers = lazy(() =>
    import("./pages/Hire/HireReactNativeDevelopers/HireReactNativeDevelopers")
);
const HireFlutterDevelopers = lazy(() =>
    import("./pages/Hire/HireFlutterDevelopers/HireFlutterDevelopers")
);
const HireGolangDevelopers = lazy(() =>
    import("./pages/Hire/HireGolangDevelopers/HireGolangDevelopers")
);
const HirePythonDevelopers = lazy(() =>
    import("./pages/Hire/HirePythonDevelopers/HirePythonDevelopers")
);
const HireSwiftDevelopers = lazy(() =>
    import("./pages/Hire/HireSwiftDevelopers/HireSwiftDevelopers")
);
const HireAngularDevelopers = lazy(() =>
    import("./pages/Hire/HireAngularDevelopers/HireAngularDevelopers")
);
const HireNodeJsDevelopers = lazy(() =>
    import("./pages/Hire/HireNodeJsDevelopers/HireNodeJsDevelopers")
);
const HireReactJsDevelopers = lazy(() =>
    import("./pages/Hire/HireReactJsDevelopers/HireReactJsDevelopers")
);
const HireVueJsDevelopers = lazy(() =>
    import("./pages/Hire/HireVueJsDevelopers/HireVueJsDevelopers")
);
const HireFullStackDevelopers = lazy(() =>
    import("./pages/Hire/HireFullStackDevelopers/HireFullStackDevelopers")
);
const HirePhpDevelopers = lazy(() =>
    import("./pages/Hire/HirePhpDevelopers/HirePhpDevelopers")
);
const HireUIUXDesigner = lazy(() =>
    import("./pages/Hire/HireUIUXDesigner/HireUIUXDesigner")
);
const HireQAEngineers = lazy(() =>
    import("./pages/Hire/HireQAEngineers/HireQAEngineers")
);
const QAServices = lazy(() =>
    import("./pages/Solutions/QAServices/QAServices")
);
const OurSolutions = lazy(() =>
    import("./pages/OurSolutions/OurSolutions")
);
/*const HireMagentoDevelopers = lazy(() =>
    import("./pages/Hire/HireMagentoDevelopers/HireMagentoDevelopers")
);*/
/*const HireJoomlaDevelopers = lazy(() =>
    import("./pages/Hire/HireJoomlaDevelopers/HireJoomlaDevelopers")
);*/
/*const HireZendDevelopers = lazy(() =>
    import("./pages/Hire/HireZendDevelopers/HireZendDevelopers")
);*/
// const Portfolio = lazy(() => import("./pages/Portfolio/Portfolio"));
const PortfolioListing = lazy(() => import("./pages/PortfolioListing/PortfolioListing"));
const CompanyOverview = lazy(() =>
    import("./pages/CompanyOverview/CompanyOverview")
);
const Testimonial = lazy(() => import("./pages/Testimonial/Testimonial"));
// const Career = lazy(() => import("./pages/Career/Career"));
// const FAQ = lazy(() => import("./pages/FAQ/FAQ"));
const ContactUs = lazy(() => import("./pages/ContactUs/ContactUs"));

/*const PigiStory = lazy(() =>
    import("./pages/SuccessStories/PigiStory/PigiStory")
);
const EnneaAppStory = lazy(() =>
    import("./pages/SuccessStories/EneaaAppStory/EnneaAppStory")
);
const BhojStory = lazy(() =>
    import("./pages/SuccessStories/BhojStory/BhojStory")
);
const ZoominStory = lazy(() =>
    import("./pages/SuccessStories/ZoominStory/ZoominStory")
);*/

// const LifeAtMi = lazy(() => import("./pages/LifeAtMi/LifeAtMi"));
const Sitemap = lazy(() => import("./pages/Sitemap/Sitemap"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy/PrivacyPolicy"));
const ThankYou = lazy(() => import("./pages/ThankYou/ThankYou"));
const ThankYouSubscribe = lazy(() =>
    import("./pages/ThankYou/ThankYouSubscribe")
);
const StaticPageWrapper = lazy(() =>
    import("./components/StaticPageWrapper/StaticPageWrapper")
);
const PageNotFound = lazy(() => import("./pages/PageNotFound/PageNotFound"));

let {portfolio} = require("./components/PortfolioSection/PortfolioList");
portfolio = portfolio
    .filter(r => r.component != null)
    .map(r => {
        r.componentCNT = lazy(() => import(`${r.component}`));
        return r;
    });

const App = (props) => {
    return (
        <Consumer>
            {context => (
                <React.Fragment>
                    <Suspense fallback={<div>Loading...</div>}>
                        <Helmet>
                            <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
                            <meta name="revisit-after" content="7 days"/>
                            <meta
                                name="viewport"
                                content="width=device-width, initial-scale=1.0, maximum-scale=1, user-scalable=no"
                            />
                            <meta name="viewport" content="width=device-width"/>
                            {/*<meta name="robots" content="index,follow"/>*/}{" "}
                            {/*uncomment while live*/}
                            <meta name="language" content="english" data-react-helmet="true"/>

                            <meta name="rating" content="general" data-react-helmet="true"/>

                            <meta name="expires" content="30" data-react-helmet="true"/>

                            <meta name="distribution" content="global" data-react-helmet="true"/>

                            <meta property="og:locale" content="en_US" data-react-helmet="true"/>

                            <meta property="og:type" content="website" data-react-helmet="true"/>

                            <meta property="og:site_name" content="Jiyosoft"
                                  data-react-helmet="true"/>

                            <meta itemProp="name" content="Jiyosoft"
                                  data-react-helmet="true"/>

                            <meta name="twitter:card" content="summary_large_image"
                                  data-react-helmet="true"/>

                            <meta name="twitter:site" content="@OceanShark_"
                                  data-react-helmet="true"/>

                            <meta name="twitter:creator" content="@OceanShark_"
                                  data-react-helmet="true"/>
                            <meta name="theme-color" content="#003ac3"/>
                        </Helmet>

                        <div className="wrapper" onMouseMove={context.handleMouseMove}>
                            <MiHeader/>
                            <MIMobileHeader/>
                            {/*    <Sidebar/>*/}
                            <div className="content-wrapper">
                                <div className="d-md-none">
                                    {/*<Header logoTheme={context.darkHeader && "white"}/>*/}
                                </div>
                                <Switch>
                                    <Route exact path="/" component={Home}/>
                                    <Route path="/services.php" component={Services}/>
                                    <Route path="/technologies.php" component={Technologies}/>
                                    <Route
                                        path="/mobile-app-development.php"
                                        component={MobileAppDevelopment}
                                    />

                                    <Route
                                        path="/iphone-application-development.php"
                                        component={IphoneAppDevelopment}
                                    />
                                    <Route
                                        path="/android-application-development.php"
                                        component={AndroidAppDevelopment}
                                    />
                                    {/*<Route
                                        path="/ipad-application-development.php"
                                        component={IpadAppDevelopment}
                                    />*/}
                                    <Route
                                        path="/swift-app-development.php"
                                        component={SwiftAppDevelopment}
                                    />
                                    <Route
                                        path="/react-native-app-development.php"
                                        component={ReactNativeDevelopment}
                                    />
                                    <Route
                                        path="/flutter-app-development.php"
                                        component={FlutterAppDevelopment}
                                    />
                                    {/*<Route
                                        path="/ionic-app-development.php"
                                        component={IonicDevelopment}
                                    />*/}
                                    <Route
                                        path="/javascript-development.php"
                                        component={JavaScriptDevelopment}
                                    />
                                    <Route
                                        path="/angularjs-development.php"
                                        component={AngularDevelopment}
                                    />
                                    <Route
                                        path="/reactjs-development.php"
                                        component={ReactJsDevelopment}
                                    />
                                    <Route
                                        path="/vuejs-development.php"
                                        component={VueJsDevelopment}
                                    />
                                    <Route
                                        path="/meteor-js-development.php"
                                        component={MeteorJsDevelopment}
                                    />
                                    <Route
                                        path="/backbone-js-development.php"
                                        component={BackboneJsDevelopment}
                                    />
                                    <Route
                                        path="/web-development.php"
                                        component={WebDevelopment}
                                    />
                                    <Route
                                        path="/php-web-development.php"
                                        component={PhpDevelopment}
                                    />
                                    <Route
                                        path="/laravel-development.php"
                                        component={LaravelDevelopment}
                                    />
                                    <Route
                                        path="/node-js-development.php"
                                        component={NodeJsDevelopment}
                                    />
                                    {/*<Route
                                        path="/cakephp-development.php"
                                        component={CakePhpDevelopment}
                                    />*/}
                                    <Route
                                        path="/golang-development.php"
                                        component={GoLangDevelopment}
                                    />
                                    {/*<Route
                                        path="/ruby-on-rails-development.php"
                                        component={RubyOnRailsDevelopment}
                                    />*/}
                                    <Route
                                        path="/python-web-development.php"
                                        component={PythonWebDevelopment}
                                    />
                                    {/*<Route
                                        path="/blockchain-development.php"
                                        component={BlockChainDevelopment}
                                    />*/}
                                    {/*<Route
                                        path="/joomla-development.php"
                                        component={JoomlaDevelopment}
                                    />*/}
                                    {/*<Route
                                        path="/wordpress-development.php"
                                        component={WordpressDevelopment}
                                    />*/}
                                    {/*<Route
                                        path="/magento-development.php"
                                        component={MagentoDevelopment}
                                    />*/}
                                    {/* <Route
                                        path="/drupal-development.php"
                                        component={DrupalDevelopment}
                                    />*/}
                                    <Route
                                        path="/internet-of-things-iot-app-development.php"
                                        component={IotAppDevelopment}
                                    />
                                    <Route
                                        path="/hire-game-developers.php"
                                        component={HireGameDevelopers}
                                    />
                                    <Route
                                        path="/game-development-services.php"
                                        component={GameDevelopment}
                                    />
                                    <Route
                                        path="/wearable-app-development.php"
                                        component={WearableAppDevelopment}
                                    />
                                    <Route
                                        path="/ibeacon-app-development.php"
                                        component={IbeaconAppDevelopment}
                                    />
                                    <Route path="/ui-ux-design-services.php" component={Design}/>
                                    <Route
                                        path="/enterprise-app-development.php"
                                        component={EnterpriseAppDevelopment}
                                    />
                                    <Route
                                        path="/product-engineering-services.php"
                                        component={ProductEngineeringServices}
                                    />
                                    <Route
                                        path="/app-development-for-startups.php"
                                        component={StartupsAppDev}
                                    />
                                    <Route
                                        path="/devops-solutions.php"
                                        component={DevOpsSplutions}
                                    />
                                    <Route
                                        path="/digital-transformation-services.php"
                                        component={DigitalTransformation}
                                    />
                                    <Route
                                        path="/ecommerce-solutions.php"
                                        component={EcommerceSolutions}
                                    />
                                    <Route
                                        path="/fintech-solutions.php"
                                        component={FintechSolutions}
                                    />
                                    <Route
                                        path="/healthcare-solutions.php"
                                        component={HealthCareSoultions}
                                    />
                                    <Route
                                        path="/fitness-solutions.php"
                                        component={FitnessSoultions}
                                    />
                                    <Route
                                        path="/food-delivery-solutions.php"
                                        component={FoodDeliverySolutions}
                                    />
                                    <Route
                                        path="/cloud-computing-solutions.php"
                                        component={CloudComputingSolutions}
                                    />
                                    <Route
                                        path="/on-demand-app-development.php"
                                        component={OnDemandAppDev}
                                    />
                                    <Route
                                        path="/real-estate-app-development.php"
                                        component={RealEstateAppDevelopment}
                                    />
                                    <Route
                                        path="/travel-app-development.php"
                                        component={TravelAppDevelopment}
                                    />
                                    <Route
                                        path="/sports-app-development.php"
                                        component={SportsAppDevelopment}
                                    />
                                    <Route
                                        path="/social-media-app-development.php"
                                        component={SocialMediaAppDevelopment}
                                    />
                                    <Route
                                        path="/business-application-development.php"
                                        component={BusinessAppDevelopment}
                                    />
                                    <Route
                                        path="/educational-app-development-solutions.php"
                                        component={EducationAppDevelopment}
                                    />
                                    <Route
                                        path="/entertainment-app-development.php"
                                        component={EntertainmentAppDevelopment}
                                    />
                                    <Route
                                        path="/hire-dedicated-developers.php"
                                        component={HireDevelopers}
                                    />
                                    <Route
                                        path="/hire-iphone-app-developers.php"
                                        component={HireIphoneDevelopers}
                                    />
                                    <Route
                                        path="/hire-android-app-developers.php"
                                        component={HireAndroidDevelopers}
                                    />
                                    {/*<Route
                                        path="/hire-ipad-app-developers.php"
                                        component={HireIpadDevelopers}
                                    />*/}
                                    {/*<Route
                                        path="/hire-ionic-developers.php"
                                        component={HireIonicDevelopers}
                                    />*/}
                                    <Route
                                        path="/hire-react-native-developer.php"
                                        component={HireReactNativeDevelopers}
                                    />
                                    <Route
                                        path="/hire-flutter-app-developers.php"
                                        component={HireFlutterDevelopers}
                                    />
                                    <Route
                                        path="/hire-golang-developers.php"
                                        component={HireGolangDevelopers}
                                    />
                                    <Route
                                        path="/hire-python-developers.php"
                                        component={HirePythonDevelopers}
                                    />
                                    <Route
                                        path="/hire-swift-developer.php"
                                        component={HireSwiftDevelopers}
                                    />
                                    <Route
                                        path="/hire-angularjs-developers.php"
                                        component={HireAngularDevelopers}
                                    />
                                    <Route
                                        path="/hire-node-js-developers.php"
                                        component={HireNodeJsDevelopers}
                                    />
                                    <Route
                                        path="/hire-reactjs-developers.php"
                                        component={HireReactJsDevelopers}
                                    />
                                    <Route
                                        path="/hire-vuejs-developer.php"
                                        component={HireVueJsDevelopers}
                                    />
                                    <Route
                                        path="/hire-full-stack-developer.php"
                                        component={HireFullStackDevelopers}
                                    />
                                    <Route
                                        path="/hire-php-developers.php"
                                        component={HirePhpDevelopers}
                                    />
                                    <Route
                                        path="/hire-ui-ux-designer.php"
                                        component={HireUIUXDesigner}
                                    />
                                    <Route
                                        path="/hire-software-qa-engineers.php"
                                        component={HireQAEngineers}
                                    />
                                    <Route
                                        path="/qa-software-testing-services.php"
                                        component={QAServices}
                                    />
                                    <Route
                                        path="/solutions.php"
                                        component={OurSolutions}
                                    />
                                    {/*<Route
                                        path="/cloud-computing-solutions.php"
                                        component={CloudComputingSolutions}
                                    />*/}
                                    {/*<Route
                                        path="/hire-magento-developers.php"
                                        component={HireMagentoDevelopers}
                                    />*/}
                                    {/*<Route
                                        path="/hire-joomla-developers.php"
                                        component={HireJoomlaDevelopers}
                                    />*/}
                                    {/*<Route
                                        path="/hire-zend-developers.php"
                                        component={HireZendDevelopers}
                                    />*/}
                                    {/*<Route
                                        exact
                                        path="/all-portfolios.php"
                                        component={Portfolio}
                                    />*/}
                                    <Route
                                        exact
                                        path="/all-portfolios.php"
                                        component={PortfolioListing}
                                    />
                                    <Route path="/about-us.php" component={CompanyOverview}/>
                                    <Route path="/testimonial.php" component={Testimonial}/>
                                    {/*<Route path="/careers.php" component={Career} />*/}
                                    {/*<Route path="/faq.php" component={FAQ} />*/}
                                    <Route path="/contact-us.php" component={ContactUs}/>
                                    <Route path="/sitemap.php" component={Sitemap}/>
                                    <Route path="/privacy-policy.php" component={PrivacyPolicy}/>
                                    <Route path="/thankyou.php" component={ThankYou}/>
                                    <Route
                                        path="/thanks-for-subscribe.php"
                                        component={ThankYouSubscribe}
                                    />
                                    {/*<Route*/}
                                    {/*    path="/(all-portfolios.php|mobile-portfolio.php|web-portfolio.php|graphic-portfolio.php|success-story.php|case-study.php)"*/}
                                    {/*    component={Portfolio}*/}
                                    {/*/>*/}
                                    {portfolio.map(
                                        (result, i) =>
                                            result.component != null && (
                                                <Route key={i} path={result.url}>
                                                    <StaticPageWrapper>
                                                        <result.componentCNT/>
                                                    </StaticPageWrapper>
                                                </Route>
                                            )
                                    )}
                                    <Route component={PageNotFound}/>
                                    {/*Life at Mi pages ends*/}
                                </Switch>
                                {props.history.location.pathname === '/contact-us.php' && <Footer/>}
                                {props.history.location.pathname !== '/contact-us.php' && <NewFooter/>}
                                <CookiePopup/>
                                <GridLines/>
                            </div>
                            {context.isMenuOpen && <Menu/>}
                        </div>
                        {/*The cursor component was here earlier*/}
                        <div/>
                    </Suspense>
                    {/*{!context.touchDevice && <Cursor />}*/}
                </React.Fragment>
            )}
        </Consumer>
    );
};
serviceWorker.register();
export default withRouter(App);
