import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import MiNav, {ContentGroup} from "./MiNav";
import "./MiHeader.scss";
import NavServices from "./NavServices";
import NavSolutions from "./NavSolutions";
import NavHire from "./NavHire";
// import NavAbout from "./NavAbout";
import LogoW from "../../jiyosoft-logo.svg";
import GridLines from "../GridLines/GridLines";
const MiHeader = () => {
    const [isTop, setIsTop] = useState(false)
    const [offset, setOffset] = useState(0)
    useEffect(() => {
        if (window.scrollY > 5) {
            if (!isTop) {
                setIsTop(true)
            }
        } else {
            if (isTop) {
                setIsTop(false)
            }
        }
        if (window.scrollY < offset) {
            setIsTop(false)
        }
        setOffset(window.scrollY)
        // eslint-disable-next-line
    }, [window.scrollY])
    return (
        <div
            className={`d-flex justify-content-between align-items-center mi-header-main ${isTop ? "top-space-h" : "top-space-b"}`}>
            <Link to="/" className="pl-30 logo d-block">
                <img
                    loading="lazy"
                    src={LogoW}
                    alt="Jiyosoft Logo"
                />
            </Link>
            <div className='d-flex'>
                {
                    !isTop &&
                    <MiNav
                        align="right"
                        rowHeight={80}
                        debug={false}
                        navClass="nav-main"
                        isTop={isTop}
                    >
                        <ContentGroup title="Services" width="1039" height="485" rootUrl="/services.php">
                            <NavServices/>
                        </ContentGroup>
                        <ContentGroup title="Solutions" width="757" height="400" rootUrl="/solutions.php">
                            <NavSolutions/>
                        </ContentGroup>
                        <ContentGroup title="Hire a Team" width="791" height="378"
                                      rootUrl="/hire-dedicated-developers.php">
                            <NavHire/>
                        </ContentGroup>
                        <ContentGroup
                          contentGroupClass="root-class"
                          title="Portfolio"
                          rootUrl="/all-portfolios.php"
                        />
                        <ContentGroup
                          contentGroupClass="root-class"
                          title="About Us"
                          rootUrl="/about-us.php"
                        />
                        {/*<ContentGroup title="About Us" width="526" height="280" rootUrl="/about-us.php">
                            <NavAbout/>
                        </ContentGroup>*/}
                        <ContentGroup
                            contentGroupClass="root-class"
                            title="Testimonials"
                            rootUrl="/testimonial.php"
                        />
                    </MiNav>
                }
                <Link to="/contact-us.php" className="nav-btn d-flex align-items-center justify-content-center">
                    Contact Us
                </Link>
            </div>
            {
                window.location.pathname !== '/all-portfolios.php' && <GridLines/>
            }
        </div>
    );
}
export default MiHeader;
